<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Videos list -->
    <div
      v-if="!isLoading"
      class="flex flex-wrap items-stretch mt-9"
      key="content"
    >
      <VideoCard
        v-for="video in data"
        :key="video.id"
        :id="video.id"
        :title="video.name"
        :expert="video.expert"
        :specialities="video.specialities"
        :isFavourite="video.isFavourite"
        :link="videoUrl + video.id"
        :isAvailable="video.isPublic || isUserHaveAccess"
        :likes="video.likes"
        :isLiked="video.like"
        type="single"
      />
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'
import VideoCard from '@/components/videos/VideoCard.vue'

export default {
  name: 'ClassContent',
  props: {
    data: {
      type: Array,
      required: false
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  components: {
    VideoCard,
    LoadingScreen,
    FadeTransition
  },
  computed: {
    ...mapGetters({
      isUserHaveAccess: 'isUserHaveAccess'
    }),
    videoUrl: function() {
      return '/pulmonology/class/videos/'
    }
  }
}
</script>
